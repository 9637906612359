import React from 'react'
import AppleIcon from './apple-icon'
import AppStoreLink from './app-store-link'
import salutationAppImage from '@images/salutation-app-example.png'

export default function Salutation() {
  return (
    <section className="stripe" id="salutation">
      <div className="container container--lg">
        <AppStoreLink id="homeBottomCTA" className="salutation">

          <div className="salutation__text">
            <span className="harper-logo" >Harper</span>
            <span className="salutation__headline">Discover what your dog can do</span>
            <div className="salutation__prompt">
              <AppleIcon />
              <span className="salutation__prompt__text">Get the app</span>
            </div>
          </div>

          <div className="salutation__image">
            <img src={salutationAppImage} alt="Screenshot of the Harper app"  />
          </div>

        </AppStoreLink>
      </div>
    </section>
  )
}
