import React from "react"
import video from '../images/slide-example.mp4'

const EvidenceSessions = () => (
  <div className="evidence--sessions">
    <div className="evidence--sessions-example">
      <video muted loop playsInline={true} autoPlay={true}>
        <source src={video} type="video/mp4"/>
      </video>
    </div>
  </div>
);

export default EvidenceSessions;
