import React from "react"

import EvidenceSessions from '@components/evidence-sessions'
import Layout from "@components/layout"
import OffSiteLink from '@components/off-site-link'
import SEO from "@components/seo"
import Salutation from "@components/salutation"

const VIPPage = () => (
  <Layout bodyClassName='furry'>
    <SEO title="For Our Extra Special Friends" />

    <section className="stripe stripe--hero stripe--centered">
      <div className="container container--lg">
        <div className="copy">
          <h1 className="pageTitle">Welcome to our pack</h1>
          <p className="lede">We can&rsquo;t wait for you and your pup to try Harper</p>
        </div>
      </div>
    </section>

    <section className="stripe stripe--alt stripe--centered" id="furry-prompt">
      <div className="container">
        <div className="copy">
          <h2 className="sectionTitle">What type of phone do you have?</h2>
          <div className="btnGroup btnGroup--block btnGroup--two">
            <a href="/vip/iphone/" className="btn">iPhone</a>
            <a href="/android/" className="btn">Android</a>
          </div>
        </div>
      </div>
    </section>

    <section className="stripe stripe--centered">
      <div className="container container--lg">
        <div className="copy">
          <h2 className="sectionTitle">A new blueprint for being a modern dog parent</h2>
          <p className="prose">Teach your pup important skills, solve behavior issues, pick up a new trick, or just play. With an ever-growing library of courses, games, and guides, you’ll never run out of ways to connect with your dog.</p>
        </div>
      </div>
      <div className="evidence evidence--overview"></div>
    </section>

    <section className="stripe stripe--centered stripe--contentLibrary">
      <div className="container container--lg">
        <div className="copy">
          <h2 className="sectionTitle">Everything you need to succeed</h2>
          <p className="prose">Follow one of our <strong>30+ curated courses</strong>, or create your own training plan from <strong>100+ games and guides</strong>. Everything has been expertly designed to help you make progress at your own pace.</p>
        </div>

        <ul className="trainingThemes">
          <li className="trainingTheme trainingTheme--puppy">
            <span className="trainingTheme__name">Puppy Camp</span>
          </li>
          <li className="trainingTheme trainingTheme--every">
            <span className="trainingTheme__name">Every Dog</span>
          </li>
          <li className="trainingTheme trainingTheme--walk">
            <span className="trainingTheme__name">Walk Like a Champ</span>
          </li>
          <li className="trainingTheme trainingTheme--crate">
            <span className="trainingTheme__name">Comfortable in the Crate</span>
          </li>
          <li className="trainingTheme trainingTheme--jump">
            <span className="trainingTheme__name">Might as Well Not Jump</span>
          </li>
          <li className="trainingTheme trainingTheme--recall">
            <span className="trainingTheme__name">Come When Called</span>
          </li>
          <li className="trainingTheme trainingTheme--sit">
            <span className="trainingTheme__name">Sit</span>
          </li>
          <li className="trainingTheme trainingTheme--down">
            <span className="trainingTheme__name">Down</span>
          </li>
          <li className="trainingTheme trainingTheme--humans">
            <span className="trainingTheme__name">Good with Humans</span>
          </li>
          <li className="trainingTheme trainingTheme--stay">
            <span className="trainingTheme__name">Stay</span>
          </li>
          <li className="trainingTheme trainingTheme--control">
            <span className="trainingTheme__name">Develop Self-Control</span>
          </li>
          <li className="trainingTheme trainingTheme--place">
            <span className="trainingTheme__name">Place</span>
          </li>
          <li className="trainingTheme trainingTheme--tug">
            <span className="trainingTheme__name">Tug</span>
          </li>
          <li className="trainingTheme trainingTheme--stand">
            <span className="trainingTheme__name">Stand</span>
          </li>
          <li className="trainingTheme trainingTheme--dogs">
            <span className="trainingTheme__name">Good with Dogs</span>
          </li>
        </ul>
      </div> 
    </section>

    <EvidenceSessions />

    <section className="stripe stripe--centered">
      <div className="container container--lg">
        <div className="copy">
          <h2 className="sectionTitle">Sessions are a blast</h2>
          <p className="prose">Training sessions are lightning fast and fun for everyone. Each day Harper recommends quick bursts of positive training&mdash;designed the way dogs and humans learn best.</p>
          <p className="prose">The training instruction has been completely reimagined. Our short, looping video stories are designed to be the perfect format for learning a game or technique at exactly the right moment. It&rsquo;s the most effective and familiar way to get smart before you train.</p>
        </div>
      </div>
    </section>

    <section className="stripe stripe--centered stripe--reversed">
      <div className="container container--lg">
        <div className="copy">
          <h2 className="sectionTitle">For all ages and experiences</h2>
          <p className="prose">No matter the age of your dog or your level of training experience, our course design and step-by-step game instruction make training fun, simple and achievable. From puppies to seniors and rookies to experts, Harper will help you learn and level up.</p>
        </div>
      </div>
    </section>

    <div className="evidence evidence--experience">
      <div className="container">
        <div className="evidence--experience-examples">
          <ul className="evidence--experience-examplesList">
            <li className="evidence--experience-example">Puppies</li>
            <li className="evidence--experience-example">Adults</li>
            <li className="evidence--experience-example">Seniors</li>
            <li className="evidence--experience-example">Rescues</li>
            <li className="evidence--experience-example">Rookies</li>
            <li className="evidence--experience-example">Veterans</li>
          </ul>
        </div>
      </div>
    </div>

    <section className="stripe stripe--centered" id="the-right-direction">
      <div className="container container--lg">
        <div className="copy">
          <h2 className="sectionTitle">Know you&rsquo;re heading in the right direction</h2>
          <h3 className="listTitle">The same positive training methods as top dog trainers</h3>
          <p className="prose">The Harper curriculum is designed in collaboration with animal behaviorists and leading force-free trainers. That means it uses positive reinforcement: well-timed rewards to encourage behaviors we want instead of punishing what we don&rsquo;t. It&rsquo;s the same approach as top dog trainers like Zak George, Susan Garrett, and more. And it&rsquo;s the most ethical, effective, and well researched approach to dog training today.</p>
          <h3 className="listTitle">Aligned with the gold standard of dog training tests</h3>
          <p className="prose">Progress through Harper will set up  your pup to ace the American Kennel Club&rsquo;s <OffSiteLink href="https://www.akc.org/products-services/training-programs/canine-good-citizen/">Canine Good Citizen Test</OffSiteLink>. This 10-step test is the gold standard for measuring a well-behaved pup, and it&rsquo;s often the first step towards therapy work, agility competitions, and other dog training conquests.</p>
        </div>
      </div>
    </section>

    <section className="stripe stripe--centered stripe--alt" id="habit">
      <div className="container container--lg">
        <div className="copy">
          <h2 className="sectionTitle">Start building the habit of building habits</h2>
          <p className="prose">Your dog and your neighbors aren&rsquo;t the only ones who will benefit from Harper. Every aspect of the Harper studio is designed to create good habits. Choose your goal. Track your sessions to measure progress. Stay motivated to chase it down.</p>
          <blockquote>
            <span>“Every action you take is a vote for the type of person you wish to become.”</span>
            <cite>James Clear, Habit Expert</cite>
          </blockquote>
          <p className="prose">Once we learn how to make a new habit stick, we&rsquo;ll have a superpower we can use in other critical areas of our lives.</p>
        </div>
      </div>
      <ul className="habitsList">
        <li className="habitsList-habit">
          <span className="habitIcon habitIcon--fitness"></span>
          <span>Fitness</span>
          </li>
          <li className="habitsList-habit">
            <span className="habitIcon habitIcon--meditation"></span>
            <span>Meditation</span>
          </li>
          <li className="habitsList-habit">
            <span className="habitIcon habitIcon--sleep"></span>
            <span>Sleep</span>
            </li>
          <li className="habitsList-habit">
            <span className="habitIcon habitIcon--finances"></span>
            <span>Finances</span>
            </li>
          <li className="habitsList-habit">
            <span className="habitIcon habitIcon--languages"></span>
            <span>Languages</span>
          </li>
      </ul>
    </section>

    <Salutation />
  </Layout>
);

export default VIPPage
